import getFetchOptions from '../utils/getFetchOptions';

import {ENDPOINT_URL} from '../constants';

export default function getUserInfo() {
	return fetch(
		`${ENDPOINT_URL}/testusercustomers/self`,
		getFetchOptions({
			method: 'GET',
		})
	);
}
