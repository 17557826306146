import React from 'react';
import {connect} from 'react-redux';
import queryString from 'query-string';
import {setTestCandidateGrade} from '../../actions/index';
import './styles.scss';
import Logo from '../../assets/logo-white-2.png';
import CustomLogin from '../CustomLogin';
import CustomPinLogin from '../../pages/CustomPinLogin';
import {initAuthAction} from '../../actions/index';

import {authCallback} from '../../utils/bankIdLogin';
import strings from '../../utils/strings';
import {
	BANK_ID,
	BANK_ID_MOBILE,
	FEIDE,
	PINCODE,
	IS_NORMING_VERSION,
	LANGUAGE,
} from '../../constants';
import getTestKeyInfo from '../../api/getTestKeyInfo';
import loginNoAuth from '../../api/loginNoAuth';
import verifyNoAuthDe from '../../api/verifyNoAuthDe';

const parsedSearch = queryString.parse(window.location.search);
const search = parsedSearch;
const params = new URLSearchParams(search);

const testKey = parsedSearch.key;
const id = params.get('id');
/* const flag = JJiisTezRREXXDSSERllliOR321Frrsaas */

const loginMethods = {
	no: [BANK_ID, BANK_ID_MOBILE, FEIDE, PINCODE],
	se: [PINCODE],
	de: [PINCODE],
};

class Login extends React.Component {
	constructor() {
		super();

		this.state = {
			email: null,
			pinCode: null,
			userPin: null,
			isLoginVisible: true,
			passwordLogin: false,
			childSelfStarting: null,
			showAltLogin:
				/* id === 'JJiisTezRREXXDSSERllliOR321Frrsaas' || */
				id === 'child-exp-oms-test' ? true : false,
			altError: null,
			instructions: null,
			requiresVerification: false,
			initialPin: null,
			dispatchAddress: null,
			testStatus: null,
			pinLogin: true,
			isDistributed: false,
		};
	}

	componentDidMount() {
		window.localStorage.removeItem('authtoken-screening');
		if (!testKey) {
			window.location.href = `/?error=true`;
			return;
		}

		const language = strings.getLanguage();

		getTestKeyInfo(testKey)
		.then((response) => {
			const email = response.email || response.dispatchAddress;
			const pinCode = response.initialPin;
			const requiresVerification = response.requiresVerification;
			const initialPin = response.initialPin;
			const dispatchAddress = response.dispatchAddress;
			const isDistributed = response.isDistributed;

			let instructionsText =
				response.status !== 'isCompleted' &&
				response.requiresVerification ? (
					<span>
						Denne testen er eldre enn en måned. For å sikre ditt
						personvern må vi bekrefte at det er riktig bruker
						starter testen. Vennligst bruk pinkoden som vi har
						sendt til{' '}
						<span style={{color: 'white'}}>
							<i>
								<u>{response.dispatchAddress}</u>
							</i>
						</span>{' '}
						for å starte testen.
					</span>
				) : null;
			if (response.status === 'isUsed') {
				instructionsText = (
					<span>
						Denne testen er startet før, men ikke fullført. For
						å sikre ditt personvern må vi bekrefte at det er
						riktig bruker starter testen. Vennligst bruk
						pinkoden som vi har sendt til{' '}
						<span style={{color: 'white'}}>
							<i>
								<u>{response.dispatchAddress}</u>
							</i>
						</span>{' '}
						for å starte testen.
					</span>
				);
			}
			const errorToThrow =
				response.status === 'isCompleted'
					? 'Denne testen er allerede fulført'
					: null;
			this.setState({
				email,
				pinCode,
				requiresVerification: false,
				pinLogin: instructionsText ? true : false,
				initialPin,
				dispatchAddress,
				altError: errorToThrow,
				instructions: instructionsText,
				isDistributed: Boolean(isDistributed),
			});
		})
		.catch((response) => {
			response.json().then((error) => {
				if (error.error === 'pincode instead') {
					this.setState({
						altError:
							'Det ser ut til at du bruker pinkode i stedet for testnøkkel for å starte testen.',
					});
				} else {
					this.setState({
						altError:
							'Testnøkkelen er ikke gyldig eller allerede brukt.',
					});
				}
			});
		});
		/* if (language === 'de' || language === 'se') {
			verifyNoAuthDe(testKey)
				.then((results) => {
					authCallback({data: JSON.stringify(results)});
					return true;
				})
				.catch(() => {
					this.setState({
						altError: 'Ikke gyldig testkode',
					});
				});
		} */
	}

	initializeAltLogin = () => {
		this.setState({
			showAltLogin: true,
		});
	};
	onAltLoginFieldChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	};
	submitAltLogin = (e) => {
		e.preventDefault();
		const {firstName, lastName} = this.state;
		const data = {testKey, firstName, lastName};

		loginNoAuth(data)
			.then((results) => {
				authCallback({data: JSON.stringify(results)});
			})
			.catch(() => {
				this.setState({
					altError: 'Ikke gyldig testkode',
				});
			});
	};

	comparePinCodes() {
		if (
			this.state.initialPin.toLowerCase() ===
			this.state.userPin.toLowerCase()
		) {
			this.setState({
				requiresVerification: false,
				instructions: null,
			});
		} else {
			this.setState({
				altError: 'Feil pinkode. Testen kan ikke startes',
			});
		}
	}

	reAffirmTestKeyInfo(testKey) {
		getTestKeyInfo(testKey).then((response) => {
			const email = response.email || response.dispatchAddress;
			const pinCode = response.pinCode || this.state.initialPin;
			this.setState({
				email,
				pinCode,
				pinLogin: true,
				showCoockieDetectionFrame: false,
			});
		});
	}

	render() {
		const {isLoginVisible, showAltLogin, altError} = this.state;

		if (!isLoginVisible) {
			return null;
		}

		const {error, iframeUrl, initAuth, isInit} = this.props;

		const showIframe = isInit && iframeUrl && !showAltLogin;
		const showError = isInit && error && !showAltLogin;
		const showLoginButtons = !isInit && !showAltLogin;
		return (
			<div className="Login">
				<header className="lit-header">
					<h1 className="lit-logo">
						<img
							className="lit-logo__img"
							src={Logo}
							alt="literate logo"
						/>
					</h1>
				</header>
				<div className="lit-wrapper--article text-center">
					{strings.getLanguage() !== 'de' &&
						strings.getLanguage() !== 'se' && <h2>Innlogging</h2>}
					{!this.state.passwordLogin && !this.state.pinLogin && (
						<p>
							Sie müssen sich anmelden, bevor Sie den Test starten können. Wählen Sie, ob Sie sich mit normaler BankID, BankID auf dem Handy, E-Mail, Feide oder PIN-Code anmelden möchten.
						</p>
					)}
					{showLoginButtons && 
					!altError &&
					!this.state.requiresVerification &&
					!this.state.passwordLogin &&
					!this.state.pinLogin && (
						<div className="bank-id-type-selector-wrapper">
							{loginMethods[LANGUAGE].includes(BANK_ID) && (
								<button
									className="lit-btn lit-btn--small bg-secondary"
									onClick={() => {
										initAuth(BANK_ID, testKey);
									}}
								>
									BankID
								</button>
							)}
							
							<button
									disabled={!this.state.isDistributed}
									className="lit-btn lit-btn--small bg-secondary"
									onClick={() => {
										this.setState({
											passwordLogin: true,
										});
									}}
								>
									Epost
								</button>

								<button
									className="lit-btn lit-btn--small bg-secondary"
									onClick={() => {
										this.reAffirmTestKeyInfo(testKey);
									}}
								>
									Pinkode
								</button>
						</div>
					)}
					{altError && (
						<div>
							<br />
							<span
								className="error"
								style={{
									color: 'yellow',
									fontSize: '18px',
									overflowWrap: 'breakWord',
								}}
							>
								<span
									style={{
										fontWeight: 'bold',
										color: '#ff6600',
										textShadow: 'black 1px 1px 1px ',
										fontSize: '20px',
										marginRight: '3px',
										overflowWrap: 'break-word',
									}}
								>
									<i>{`Error: `}</i>
								</span>
								<span>{'	 '}</span>
								{altError}
							</span>
						</div>
					)}
					{!altError && this.state.instructions && (
						<div>
							<br />
							<span
								className="error"
								style={{
									color: 'yellow',
									fontSize: '18px',
									overflowWrap: 'breakWord',
								}}
							>
								<span
									style={{
										fontWeight: 'bold',
										color: '#ff6600',
										textShadow: 'black 1px 1px 1px ',
										fontSize: '20px',
										marginRight: '3px',
										overflowWrap: 'break-word',
									}}
								>
									<i>{` `}</i>
								</span>
								<span>{'	 '}</span>
								{this.state.instructions}
							</span>
						</div>
					)}
					{this.state.passwordLogin && (
						<div className="lit-customlogin-sub-wrapper">
							<CustomLogin />
						</div>
					)}
					{this.state.pinLogin && this.state.email && (
						<div className="lit-customlogin-sub-wrapper">
							<CustomPinLogin
								email={this.state.email}
								pinCode={this.state.pinCode}
							/>
						</div>
					)}
					{showError && <div>{error}</div>}
					{showAltLogin && (
						<div className="alternate-login">
							<p>
								For å logge inn uten BankID eller Feide trenger
								vi å vite hva du heter
							</p>
							<form onSubmit={this.submitAltLogin}>
								<label>
									Fornavn
									<input
										type="text"
										name="firstName"
										id="firstName"
										onChange={this.onAltLoginFieldChange}
									/>
								</label>
								<label>
									Etternavn
									<input
										type="text"
										name="lastName"
										id="lastName"
										onChange={this.onAltLoginFieldChange}
									/>
								</label>
								{altError && (
									<p className="error">{altError}</p>
								)}
								<button
									className="lit-btn lit-btn--small"
									type="submit"
								>
									Logg inn
								</button>
							</form>
						</div>
					)}
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const {error, iframeUrl, isInit} = state.auth;

	return {
		error,
		iframeUrl,
		isInit,
	};
};

const mapDispatchToProps = {
	initAuth: initAuthAction,
	onSetTestCandidateGrade: setTestCandidateGrade,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
